<template>
  <div class="ready-img">
    <fm-btn class="border-btn" @click="chooseImg('screen')">大屏封面</fm-btn>
    <fm-btn class="border-btn" @click="chooseImg('my')">我的考核封面</fm-btn>
    <fm-modal
      :mask-closable="false"
      :value="openDialog"
      width="50%"
      :title="type === 'screen' ? '大屏封面' : '我的考核封面'"
      v-if="openDialog"
      theme="mh-withe"
      @cancel="openDialog = false;fileDataSrc=null;fileData=null;">
      <template v-if="!fileData">
        <img :src="showImg" v-if="showImg" style="width: 100%" v-loading="loading" @click="$refs.upload.click()"/>
        <div class="sc-btn" v-loading="loading">
          <fm-btn @click="$refs.upload.click()" style="margin: 1rem;">选择图片</fm-btn>
        </div>
      </template>
      <template v-else>
        <img :src="fileDataSrc" style="width: 100%" @click="$refs.upload.click()">
        <div class="sc-btn" v-loading="loading">
          <fm-btn @click="upload" style="margin: 1rem;">确认上传</fm-btn>
        </div>
      </template>
      <input type="file" style="display: none;" @change="fileChange" ref="upload">
    </fm-modal>
  </div>
</template>

<script>
import { fileRequest } from '@/api'

export default {
  props: {
    appraisalId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      fileData: null,
      fileDataSrc: null,
      loading: false,
      openDialog: false,
      type: '',
      screenImg: null,
      myImg: null
    }
  },
  computed: {
    path () {
      return '/appraisal/' + this.appraisalId + '/'
    },
    showImg () {
      return this.type === 'screen' ? this.screenImg : this.myImg
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async fileChange () {
      this.fileData =  null
      let files = this.$refs.upload.files
      if (files.length) {
        let file = files[0]
        if (['.png', '.jpg', '.jpeg', '.gif'].findIndex(v => file.name && new RegExp(v, 'i').test(file.name)) === -1) {
          this.$notice.info('请选择图片')
          return
        } else {
          this.fileData = file
          this.fileDataSrc = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
          })
        }
      }
      // this.upload()
    },
    async upload () {
      let file = this.fileData
      let fileName = this.type + '.' + file.name.split('.')[file.name.split('.').length - 1]
      let newFile  = new File([file], fileName, {type: file.type});
      this.loading = true
      let parm = new FormData()
      parm.append('file', newFile)
      parm.append('path', this.path)
      await fileRequest.upload(parm)
      this.fileData = null
      this.fileDataSrc = null
      this.$notice.success('上传成功')
      this.loading = false
      this.loadData()
    },
    async loadData () {
      this.loading = true
      try {
        let data = await fileRequest.get({
          path: this.path
        })
        let screenimg = data.find((v) => v.name.includes('screen'))
        if (screenimg) {
          const fileData = await fileRequest.download({
            path: screenimg.path
          })
          this.screenImg = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
        } else {
          this.screenImg = null
        }
        let myimg = data.find((v) => v.name.includes('my'))
        if (myimg) {
          const fileData = await fileRequest.download({
            path: myimg.path
          })
          this.myImg = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
        } else {
          this.myImg = null
        }
      } catch (e) {
        this.loading = false
      }
      this.loading = false
    },
    chooseImg (type) {
      this.type = type
      this.openDialog = true
    }
  }
}
</script>

<style lang="less" scoped>
.sc-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}
.ready-img {
  padding-right: 10px;
}
.border-btn {
  background-color: #fff !important;
  color: #00CCC4 !important;
  border: 1px solid #00CCC4 !important;
  margin-right: 5px;
  padding: 0 22px;
}
</style>